module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"illdefined","short_name":"illdefined","start_url":"/","background_color":"#ffffff","theme_color":"#326EF5","display":"minimal-ui","icon":"src/assets/logo/512_512.png","icons":[{"src":"src/assets/logo/25_25.png","sizes":"25x25","type":"image/png"},{"src":"src/assets/logo/72_72.png","sizes":"72x72","type":"image/png"},{"src":"src/assets/logo/96_96.png","sizes":"96x96","type":"image/png"},{"src":"src/assets/logo/144_144.png","sizes":"144x144","type":"image/png"},{"src":"src/assets/logo/192_192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/logo/256_256.png","sizes":"256x256","type":"image/png"},{"src":"src/assets/logo/384_384.png","sizes":"384x384","type":"image/png"},{"src":"src/assets/logo/512_512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bfa9afe1766913115ad93baf5ad80a46"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
